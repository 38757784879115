<template>
  <q-card class="documentCard shadow-4">
    <q-card-section>
      <div class="row justify-between">
        <div class="col text-subtitle2">
          {{ document.filename }}
        </div>
        <div class="col text-right">
          <span class="text-bold">{{ document.type }}</span>
          <div class="text-caption">
            {{ document.size }}
          </div>
        </div>
      </div>
    </q-card-section>
    <q-card-actions align="right">
      <q-btn
        dense
        size="16px"
        :label="$t('download')"
        no-caps
        color="primary"
        @click.stop="openURL(document.url)"
      />
    </q-card-actions>
  </q-card>
</template>
<script>
import { openURL } from 'quasar'
export default {
  props: ['document'],
  methods: {
    openURL (url) {
      openURL(url)
    }
  }
}
</script>
<style lang="stylus" scoped>
.documentCard
  background-color darken(white, 10)
  box-shadow 0 2px 4px -1px rgba(0,0,0,0.1), 0 4px 5px rgba(0,0,0,0.07), 0 1px 10px rgba(0,0,0,0.06)
</style>
