<template>
  <q-page v-if="booking" class="justify-center">
    <div
      v-if="this.$q.platform.is.desktop"
      :style="{backgroundImage: `url(${cityImg})`, backgroundColor: 'grey'}"
      class="row hero items-center justify-center"
    >
      <div class="col-1 icon-type">
        <m-travel-icon :type="booking.type" color="white" :bg-color="getTravelContentHex(booking.type)" :size="$q.platform.is.mobile ? '50px' : '150px'" circle />
      </div>
      <div class="col-10 text-right legible">
        <h5>{{ booking.info }}</h5>
        <h5>{{ booking.travel_type }}</h5>
        <h3>{{ booking.name }}</h3>
      </div>
    </div>

    <q-tabs
      v-if="!$q.platform.is.mobile"
      align="justify"
      active-color="primary"
      class="shadow-1 bg-grey-2 text-faded"
      :class="{
        'absolute-center': $q.platform.is.desktop
      }"
    >
      <q-route-tab
        :to="`/bookings/${booking.reference}`"
        :label="$t('details')"
        name="details"
      />
      <q-route-tab
        :to="`/bookings/${booking.reference}/invoices`"
        :label="$tc('invoice.invoice', 2)"
        name="invoices"
      />
      <q-route-tab
        :to="`/bookings/${booking.reference}/documents`"
        :label="$tc('documents')"
        name="documents"
      />
    </q-tabs>

    <router-view
      :booking="booking"
    />
  </q-page>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import travelContents from 'mixins/travelContents'
import { get as booking } from 'api/bookings'
import loading from 'utils/loading'
import store from 'store'
import { MTravelIcon } from 'components/'

export default {
  name: 'BookingShow',
  components: { MTravelIcon },
  mixins: [travelContents, authentication],
  data () {
    return {
      booking: null
    }
  },
  computed: {
    tab () {
      return this.$route.params.tab || 'details'
    },
    cityImg () {
      if (this.booking && this.booking.type) {
        return require(`assets/${this.getTravelContentBackground(this.booking.type)}`)
      }
      return null
    }
  },
  watch: {
    '$route.params.reference' () {
      if (this.$route.name !== 'bookings-show') return
      this.getBookingDetails()
    }
  },
  created () {
    if (this.$route.name !== 'bookings-show') return
    this.getBookingDetails()
  },
  methods: {
    getBookingDetails () {
      const partner = store.getters.partner
      loading.start({
        message: this.$t('loading.booking_details'),
        partnerSlug: partner.slug,
        spinnerColor: partner.hex
      })
      booking(this.$route.params.reference, { include: 'notes,latest' })
        .then(response => {
          if (response.data.type === 'bikehire') {
            this.booking = {
              ...response.data,
              notes: {
                data: [
                  {
                    reference: response.data.reference,
                    body: 'IMPORTANT: Once you have collected your bike, you will need to book a return slot no more than 48 hours before you intend to return it. To do so, contact Brompton on 0203 474 0700'
                  }
                ]
              }
            }
          } else {
            this.booking = response.data
          }
        })
        .finally(() => { loading.stop() })
    }
  }
}
</script>

<style lang="stylus" scoped>
.hero
  min-height 100px
  color rgba(255, 255, 255, 1)
  overflow hidden
  background-position 0 35%
  background-size cover
  text-overflow: clip;

  @media (min-width: 768px)
    padding 32px
    min-height 240px

h5
  @media (max-width 768px)
    font-size 20px
    line-height 1

h3
  @media (max-width 768px)
    font-size 24px
    line-height 1

.q-tabs
  top initial
  width 80%
  max-width 768px
  z-index 1

.layout-padding
  max-width 1024px

.legible
  background rgba(0, 0, 0, 0.3)
  -webkit-text-stroke 0.5px black
  padding-inline-start 75px
  max-width 768px

.icon-type
  z-index 1
</style>
