<template>
  <div v-if="booking">
    <div class="sub-header">
      <m-icons-type
        class="icon"
        :type="booking.type"
        rounded
      />
      {{ booking.name }}
    </div>
    <div class="tickets-holder">
      <div
        v-for="(ticket, i) in documents"
        :key="ticket.id"
        class="ticket"
        @click="openURL(ticket.url)"
      >
        <div class="leg">
          {{ `Ticket ${ i + 1}` }}
        </div>
        <div class="ref">
          {{ $t('booking.ref') }}: {{ booking.reference }}
        </div>
        <q-icon
          class="chevron"
          name="fas fa-chevron-right"
          size="28px"
        />
      </div>
      <!-- <div
        v-for="ticket in documents"
        :key="ticket.id"
        class="ticket"
        @click="openURL(ticket.url)"
      >
        <div class="leg">
          {{ $t('train.outbound') }}
        </div>
        <div class="date">
          {{ booking.attributes.outbound_legs[0].depart_at | toDate }}
        </div>
        <div class="atocs">
          {{ outboundAtocs }}
        </div>
        <div class="time">
          {{ booking.attributes.outbound_legs[0].depart_at | toTime }} - {{ booking.attributes.outbound_legs.slice(-1)[0].arrive_at | toTime }}
        </div>
        <div class="ref">
          {{ $t('booking.ref') }}: {{ booking.reference }}
        </div>
        <div class="type">
          {{ outboundTicketType | toTitleCase }}
        </div>
        <q-icon
          class="chevron"
          name="fas fa-chevron-right"
          size="28px"
        />
      </div>
      <div v-if="booking.attributes.return_legs">
        <div
          v-for="ticket in documents"
          :key="ticket.id + '-return'"
          class="ticket"
          @click="openURL(ticket.url + '#page=2')"
        >
          <div class="leg">
            {{ $t('train.return') }}
          </div>
          <div class="date">
            {{ booking.attributes.return_legs[0].depart_at | toDate }}
          </div>
          <div class="atocs">
            {{ returnAtocs }}
          </div>
          <div class="time">
            {{ booking.attributes.return_legs[0].depart_at | toTime }} - {{ booking.attributes.return_legs.slice(-1)[0].arrive_at | toTime }}
          </div>
          <div class="ref">
            {{ $t('booking.ref') }}: {{ booking.reference }}
          </div>
          <div class="type">
            {{ returnTicketType | toTitleCase }}
          </div>
          <q-icon
            class="chevron"
            name="fas fa-chevron-right"
            size="28px"
          />
        </div>
      </div> -->
    </div>
    <div class="sub-footer">
      <q-icon name="fas fa-hand-point-up" size="30px" left />
      {{ $t('booking.tap_ticket') }}
    </div>
  </div>
</template>

<script>
import Booking from 'store/models/booking'
import { documents } from 'api/bookings'
import loading from 'utils/loading'
import { MIconsType } from 'components/'
import date from 'utils/date-time'
import { toTitleCase } from 'utils/utils'

export default {
  filters: {
    toDate (val) {
      return date.toMediumShortDate(val)
    },
    toTime (val) {
      return date.toCivilTime(val)
    },
    toTitleCase (val) {
      return toTitleCase(val)
    }
  },
  components: { MIconsType },
  data () {
    return {
      documents: null
    }
  },
  computed: {
    booking () {
      const booking = Booking.find(this.$route.params.reference)
      return booking
    },
    outboundAtocs () {
      const { outbound_legs } = this.booking.attributes
      const start = outbound_legs[0].depart_atoc
      const end = outbound_legs.slice(-1)[0].arrive_atoc
      return `${start} - ${end}`
    },
    outboundTicketType () {
      return this.booking.attributes.outbound_legs[0].ticket_type
    },
    returnAtocs () {
      if (!this.booking.attributes.return_legs) return
      const { return_legs } = this.booking.attributes
      const start = return_legs[0].depart_atoc
      const end = return_legs.slice(-1)[0].arrive_atoc
      return `${start} - ${end}`
    },
    returnTicketType () {
      if (!this.booking.attributes.return_legs) return
      return this.booking.attributes.return_legs[0].ticket_type
    }
  },
  async created () {
    const partner = this.$store.getters.partner
    loading.start({
      message: this.$t('loading.booking_details'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      // If we don't have the booking stored because of a page refresh, fetch it again now
      const booking = Booking.find(this.$route.params.reference)
      if (!booking) await this.$store.dispatch('entities/bookings/loadBooking', this.$route.params.reference)

      const { data } = await documents(this.$route.params.reference)
      this.documents = data
    } catch (err) {
      console.log(err)
    } finally {
      loading.stop()
    }
  },
  methods: {
    openURL (url) {
      if (url) window.open(url, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.sub-header
  position sticky
  top 0
  background white
  width 100vw
  z-index 2
  box-shadow 0 3px 5px 1px #ccc
  display flex
  color #444
  font-size 110%
  font-weight 550
  align-items center
  padding 10px 20px
  .icon
    margin-right 10px

.tickets-holder
  height 100vh

.sub-footer
  display flex
  align-items center
  justify-content: center
  position fixed
  bottom 55px
  left 0
  right 0
  padding 10px
  z-index 2
  background var(--q-color-primary)
  color white

.ticket
  width 90vw
  height 150px
  margin 20px 5vw
  color black
  border solid black 1px
  border-image url('~assets/ticket-border.svg') 20 / 20
  padding 10px 20px
  display grid
  grid-template-columns 2fr 1fr
  grid-template-rows auto auto 1fr auto auto
  grid-template-areas "leg date" \ "atocs time" \ "... ..." \ "ref chevron" \ "type chevron"
  .leg
    grid-area leg
    font-size 115%
    font-weight 550
  .date
    grid-area date
    justify-self end
  .atocs
    grid-area atocs
    color var(--q-color-primary)
    font-size 105%
  .time
    grid-area time
    justify-self end
  .ref
    grid-area ref
  .type
    grid-area type
    color var(--q-color-primary)
  .chevron
    grid-area chevron
    color var(--q-color-primary)
    justify-self end
    align-self end
    margin-right -8px
    margin-bottom 8px
</style>
