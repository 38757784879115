<template>
  <component :is="component" />
</template>

<script type="text/javascript">
import { hasPermission } from 'utils/authentication'
import userBooking from './user-bookings/show'
import backOfficeBooking from './backoffice-bookings'
import userBookings from './user-bookings'
import mobileBooking from './mobile-bookings'
export default {
  name: 'Bookings',
  computed: {
    component () {
      if (hasPermission('can.book.for.anyone')) {
        return backOfficeBooking
      } else {
        if (this.$route.params.reference) {
          if (this.$q.platform.is.mobile) {
            return mobileBooking
          } else {
            return userBooking
          }
        } else {
          return userBookings
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 1024px
</style>
