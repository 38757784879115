<template>
  <div>
    <status-map v-if="points" :points="points" :start-location="startLocation" />
    <status-box v-if="notes" :notes="notes" />
  </div>
</template>

<script>
import statusBox from './status-box'
import statusMap from './map'
import { get } from 'api/bookings'
import { query } from 'api/location'
import loading from 'utils/loading'

export default {
  components: { statusBox, statusMap },
  data () {
    return {
      points: null,
      startLocation: null,
      notes: null,
      timerInterval: null
    }
  },
  created () {
    this.updateTaxiLocation()
    this.startTimer()
  },
  methods: {
    async updateTaxiLocation () {
      const { ref } = this.$route.params
      loading.start({
        message: this.$t('loading.updating.taxi_status'),
        partnerSlug: this.$store.getters.partner.slug,
        spinnerColor: this.$store.getters.partner.hex
      })

      try {
        const { data: { journey, notes, summary } } = await get(ref, { include: 'notes,journey' })
        if (!this.startLocation) {
          const [place] = await query(summary.start_postcode)
          this.startLocation = { lat: Number(place.lat), lng: Number(place.lng) }
        }
        this.points = journey.data.points.data
        this.notes = notes.data
      } catch (err) {
        let errMsg = 'Unexpected error'
        if (err.data?.detail?.[0]) {
          errMsg = err.data.detail[0]
        }
        this.$q.notify(this.$t('error.custom', { error: errMsg }))
      } finally {
        loading.stop()
      }
    },
    startTimer () {
      this.timerInterval = setInterval(this.updateTaxiLocation, 10000)
    }
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.timerInterval)
    next()
  }
}
</script>
