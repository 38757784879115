<template>
  <div
    class="booking-timeline"
    @click="goToBooking"
  >
    <m-travel-icon
      class="icon"
      color="white"
      :bg-color="getTravelContentHex(type)"
      :type="type"
      size="32px"
      circle
    />
    <div v-if="!['ridehailing'].includes(type)" :class="['carclub', 'rental', 'hotel'].includes(type) ? 'start-loc' : 'start-time'">
      {{ startTime | toTime }}  {{ ['rental', 'hotel'].includes(type) ? toDayOfMonth(startTime) : '' }}
    </div>
    <div v-if="!['ridehailing'].includes(type)" :class="['carclub', 'rental', 'hotel'].includes(type) ? 'start-time strong' : 'start-loc'">
      {{ startLocation }}
    </div>
    <div v-else class="start-loc address-span">
      <strong>{{ $t('booked_for') }}:</strong> {{ startTime | toTime }}<br>
      {{ startLocation }}
    </div>
    <div class="grey-box">
      <div class="type">
        {{ $tc(`content_type.${type}`) }}
      </div>
      <div v-if="greyBoxInfo" class="grey-box-info">
        {{ greyBoxInfo }}
      </div>
      <div v-if="booking.price" class="price">
        {{ booking.price.total.text }}
      </div>
      <div class="duration">
        {{ toDuration(startTime, endTime) }}
      </div>
    </div>
    <div :class="['carclub', 'rental', 'hotel'].includes(type) ? 'end-time strong' : 'end-loc'">
      {{ endLocation }}
    </div>
    <div
      v-if="!['ridehailing', 'bikehire', 'drt'].includes(type)"
      :class="['carclub', 'rental', 'hotel'].includes(type) ? 'end-loc' : 'end-time'"
    >
      {{ endTime | toTime }} {{ ['rental', 'hotel'].includes(type) ? toDayOfMonth(endTime) : '' }}
    </div>
    <div v-else class="end-time strong">
      {{ $t('drop_off') }}:
    </div>
    <div class="timeline" :style="`--timeline-color: ${timelineColor}`">
      <div class="dot" />
      <div class="line" />
      <div class="dot" />
    </div>
    <slot class="transfer" name="transfer" />
  </div>
</template>

<script>
import date from 'utils/date-time'
import { MTravelIcon } from 'components/'
import travelContents from 'mixins/travelContents'
const { toMediumDate, toCivilTime, getDateDiff, minutesToHours, toDayOfMonth } = date

export default {
  filters: {
    toDate (val) {
      return toMediumDate(val)
    },
    toTime (val) {
      return toCivilTime(val)
    }
  },
  components: { MTravelIcon },
  mixins: [ travelContents ],
  props: {
    booking: Object
  },
  computed: {
    type () {
      const transportMode = this.booking.attributes?.outbound_legs?.[0]?.transport_mode
      if (transportMode) return transportMode
      return this.booking.type
    },
    startLocation () {
      const { attributes, lookups, type } = this.booking
      switch (type) {
      case 'ridehailing':
        return attributes?.start_location
      case 'rental':
      case 'carclub':
        return this.$t('pick_up')
      case 'hotel':
        return this.$t('check_in')
      case 'rail':
        return lookups[attributes?.outbound_legs?.[0]?.depart_atoc] + ' (' + attributes?.outbound_legs?.[0]?.depart_atoc + ')'
      case 'flight':
        return lookups[attributes?.outbound_legs?.[0]?.depart_iata].name + ' (' + attributes?.outbound_legs?.[0]?.depart_iata + ')'
      case 'bikehire':
        return attributes.station_name
      case 'drt':
        return attributes.pickup_location
      case 'ferry':
        return attributes.outbound_legs[0].depart_port
      case 'bus':
        return attributes.origin
      default:
        return undefined
      }
    },
    endLocation () {
      const { attributes, lookups, type } = this.booking
      switch (type) {
      case 'ridehailing':
        return attributes?.end_location
      case 'rental':
      case 'carclub':
        return this.$t('drop_off')
      case 'hotel':
        return this.$t('check_out')
      case 'rail':
        return lookups[attributes?.outbound_legs?.[attributes.outbound_legs.length - 1]?.arrive_atoc] + ' (' + attributes?.outbound_legs?.[attributes.outbound_legs.length - 1]?.arrive_atoc + ')'
      case 'flight':
        return lookups[attributes?.outbound_legs?.[attributes.outbound_legs.length - 1]?.arrive_iata].name + ' (' + attributes?.outbound_legs?.[attributes.outbound_legs.length - 1]?.arrive_iata + ')'
      case 'drt':
        return attributes.dropoff_location
      case 'ferry':
        return attributes.outbound_legs[0].arrive_port
      case 'bus':
        return attributes.destination
      default:
        return undefined
      }
    },
    startTime () {
      switch (this.booking.type) {
      case 'rental':
      case 'ridehailing':
      case 'carclub':
        return this.booking.attributes?.depart_at
      case 'hotel':
        return this.booking.attributes?.check_in
      case 'rail':
      case 'flight':
      case 'ferry':
        return this.booking.attributes?.outbound_legs?.[0]?.depart_at
      case 'bikehire':
      case 'drt':
        return this.booking.attributes?.pickup_time
      case 'bus':
        return this.booking.attributes.departing.split(' ')[0] + ' ' + this.booking.attributes.departing.split(' ')[1]
      default:
        return undefined
      }
    },
    endTime () {
      switch (this.booking.type) {
      case 'rental':
      case 'carclub':
        return this.booking.attributes?.arrive_at
      case 'hotel':
        return this.booking.attributes?.check_out
      case 'rail':
      case 'flight':
        return this.booking.attributes?.outbound_legs?.[this.booking.attributes.outbound_legs.length - 1]?.arrive_at
      case 'ferry':
        return this.booking.attributes.outbound_legs[0].arrive_at
      case 'bus':
        return this.booking.attributes.arriving.split(' ')[0] + ' ' + this.booking.attributes.arriving.split(' ')[1]
      default:
        return undefined
      }
    },
    timelineColor () {
      const color = this.getTravelContentHex(this.type)
      return color || '#4361EE'
    },
    greyBoxInfo () {
      const { attributes } = this.booking
      switch (this.type) {
      default:
        return null
      case 'flight':
        return attributes.outbound_legs[0].flight_number
      case 'carclub':
        return attributes.vehicle
      }
    }
  },
  methods: {
    toDayOfMonth,
    goToBooking () {
      if (this.$route.name === 'bookings-show') return
      const [path] = this.booking.reference.split('-')
      this.$router.push(`/bookings/${path}`)
    },
    toDuration (start, end) {
      if (!end) return ''
      if (this.type === 'hotel') {
        const days = getDateDiff(date.toCivilDate(end), date.toCivilDate(start), 'days').days
        return this.$tc('num.nights', days)
      }
      if (this.type === 'flight') {
        if (this.booking.attributes.outbound_legs[0].duration) {
          // Older flights won't have duration, nor will flights on staging, and can't be 100% sure all flights on production will either
          const duration = this.booking.attributes.outbound_legs.reduce((accumulator, value) => { return accumulator + value.duration.value }, 0)
          return minutesToHours(duration)
        } else {
          // In which case, better to show nothing than to show something silly
          return
        }
      }
      const val = getDateDiff(end, start, 'minutes')
      return minutesToHours(val.minutes)
    }
  }
}
</script>

<style lang="stylus" scoped>
.booking-timeline
  line-height 1.3em
  font-size 90%
  padding 10px 30px
  display grid
  grid-template-columns 20px 30px 70px auto 30px 30px
  grid-template-rows repeat(5, auto)
  grid-row-gap 15px
  grid-template-areas \
  "timeline ... start-time start-loc start-loc none" \
  "timeline icon grey-box grey-box grey-box grey-box" \
  "timeline icon grey-box grey-box grey-box grey-box" \
  "timeline icon grey-box grey-box grey-box grey-box" \
  "timeline ... end-time end-loc end-loc ..." \
  "transfer transfer transfer transfer transfer transfer"
  color black

.strong
    font-weight 550

.icon
  grid-area icon
  align-self center
.start-time
  grid-area start-time
  align-self start
.end-time
  grid-area end-time
  align-self end
.start-loc
  grid-area start-loc
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
  align-self start
.end-loc
  grid-area end-loc
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
  align-self end
.price
  grid-area price
  justify-self flex-end
  font-weight 550
.timeline
  margin 5px 0
  grid-area timeline
  display grid
  grid-template-rows 12px auto 12px
  align-self stretch
  align-items center
  justify-items center
  .dot
    background var(--timeline-color)
    border-radius 50%
    width 13px
    height 13px
  .line
    background var(--timeline-color)
    height 100%
    width 2px
.type
  grid-area type
  color var(--q-color-primary)
  font-weight 800
.duration
  grid-area duration
  color var(--q-color-primary)
  font-weight 450
  align-self end
.grey-box-info
  grid-area grey-box-info
  font-size 90%
  margin 2px 0

.grey-box
  background rgba(100, 100, 100, 0.05)
  grid-area grey-box
  border-radius 15px
  margin 0 20px 0 15px
  padding 7px 12px
  min-height 5em
  display grid
  grid-template-columns 1fr 1fr
  grid-template-areas "type price" "grey-box-info grey-box-info" "duration ..."

.transfer
  grid-area transfer

.address-span
  grid-column 3 / span 4
</style>
