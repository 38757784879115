<template>
  <q-page class="layout-padding">
    <q-list class="bg-grey-2" bordered separator>
      <q-expansion-item :label="$t('booking.breakdown')" default-opened>
        <q-list class="bg-white" separator>
          <q-item>
            <q-item-section>
              <q-item-label>{{ $t('booking.fee') }}:</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label class="text-bold">
                {{ booking.price.booking_fee.text }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>{{ $t('net_amount') }}:</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label class="text-bold">
                {{ booking.price.net.text }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>{{ $t('tax.amount') }}:</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label class="text-bold">
                {{ booking.price.tax.text }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-expansion-item>

      <q-item>
        <q-item-section>
          <q-item-label>{{ $t('total_cost') }}:</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-item-label class="text-bold">
            {{ booking.price.total.text }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-page>
</template>
<script>
export default {
  props: {
    booking: Object
  }
}
</script>
<style lang="stylus" scoped>
.layout-padding
  @media (max-width: 1199px)
    padding 1em
</style>
