<template>
  <div
    v-if="booking && !loading"
    class="booking-container"
    :style="`--footer-height:${footerHeight}`"
  >
    <div class="sub-header">
      <m-travel-icon
        :type="booking.type"
        :color="getTravelContentHex(booking.type)"
        size="48px"
      />
      <div class="booking-name">
        {{ booking.name }}
      </div>
      <div class="booking-reference">
        {{ $t('booking_complete.reference', { partner: $store.getters.partner.name }) + ': ' + booking.reference }}
      </div>
    </div>
    <div class="content-container">
      <!-- For outbound/return flippable journeys -->
      <div v-if="booking.attributes.outbound_legs" class="flip-container">
        <div class="flip preload" :style="`--child-height: ${containerHeight}px`">
          <div class="outbound">
            <h6>{{ $t('train.outbound') }}</h6>
            {{ toDateSpan(startTime, endTime) }}
            <timeline
              v-for="(leg, i) in booking.attributes.outbound_legs"
              :key="'outbound' + i"
              :booking="{
                ...booking,
                attributes: {
                  ...booking.attributes,
                  outbound_legs: [leg]
                },
                price: null
              }"
            >
              <template v-if="i < booking.attributes.outbound_legs.length -1" v-slot:transfer>
                <div class="transfer">
                  <q-icon
                    name="fas fa-history"
                    size="24px"
                    left
                  />
                  {{ getDateDiff(booking.attributes.outbound_legs[i + 1].depart_at, leg.arrive_at, 'minutes').minutes | minutesToHours }} {{ $t('booking.transfer_time') }}
                </div>
              </template>
            </timeline>
            <button
              v-if="booking.attributes.return_legs.length"
              class="view-return-btn"
              @click="toggleOutboundReturn"
            >
              {{ $t('booking.view_return') }}
            </button>
          </div>
          <div v-if="booking.attributes.return_legs.length" class="return">
            <h6>{{ $t('train.return') }}</h6>
            {{ toDateSpan(booking.attributes.return_legs[0].depart_at, booking.attributes.return_legs.slice(-1)[0].arrive_at) }}
            <timeline
              v-for="(leg, i) in booking.attributes.return_legs"
              :key="'return' + i"
              :booking="{
                ...booking,
                attributes: {
                  ...booking.attributes,
                  outbound_legs: [leg]
                },
                price: null
              }"
            >
              <template v-if="i < booking.attributes.return_legs.length -1" v-slot:transfer>
                <div class="transfer">
                  <q-icon
                    name="fas fa-history"
                    size="24px"
                    left
                  />
                  {{ getDateDiff(booking.attributes.return_legs[i + 1].depart_at, leg.arrive_at, 'minutes').minutes | minutesToHours }} {{ $t('booking.transfer_time') }}
                </div>
              </template>
            </timeline>
            <button
              v-if="booking.attributes.return_legs"
              class="view-return-btn"
              @click="toggleOutboundReturn"
            >
              {{ $t('booking.view_outbound') }}
            </button>
          </div>
        </div>
      </div>
      <!-- For one-way non-flippable journeys -->
      <div v-else class="timeline-container">
        {{ toDateSpan(startTime, endTime) }}
        <timeline
          :booking="{ ...booking, price: null }"
        />
      </div>
      <!-- Booking details -->
      <div v-if="bookingDetails" class="extra-info">
        <strong class="primary">{{ $t('booking.details') }}</strong>
        <div v-for="detail in bookingDetails" :key="detail.label">
          <div v-if="detail.text" class="booking-details">
            <strong>{{ detail.label }}:</strong> <span>{{ detail.text }}</span>
          </div>
        </div>
      </div>
      <div v-if="bookingReference" class="extra-info">
        <strong class="primary">{{ $t('booking_complete.reference', { partner: $tc(`content_type.${booking.type}`) }) }}</strong><br>
        {{ bookingReference }}
      </div>
    </div>
    <div class="sub-footer">
      <button @click="amend">
        {{ $t('amend') }}
      </button>
      <button @click="cancel">
        {{ $t('cancel') }}
      </button>
      <button @click="details">
        {{ $t('details') }}
      </button>
      <hr class="span-3">
      <div class="span-3 booking-price">
        {{ $t('total_cost') }}: {{ booking.price.total.text }}
      </div>
      <div v-if="booking.travel_type" class="span-3 booking-travel-type">
        {{ booking.travel_type }}
      </div>
      <button v-if="['rail'].includes(booking.type)" class="span-3 ticket-btn" @click="$router.push({ name: 'booking-tickets' })">
        {{ $t('booking.view_ticket') }}
      </button>
      <button v-if="['gohi','swift'].includes($store.getters.partner.slug) && ['bus'].includes(booking.type)" class="span-3 ticket-btn" @click="$router.push({ name: 'Tickets' })">
        {{ $t('booking.view_tickets') }}
      </button>
      <button class="span-3 done-btn" @click="$router.go(-1)">
        {{ $t('done') }}
      </button>
    </div>
    <details-modal
      :show="showDetails"
      :details="booking.details"
      :notes="booking.notes.data"
      @close-modal="details"
    />
  </div>
</template>

<script>
import Timeline from 'pages/journeys/show/timeline/booking.vue'
import loading from 'utils/loading'
import date from 'utils/date-time'
import { MTravelIcon } from 'components/'
import DetailsModal from './details-modal'
import Booking from 'store/models/booking'
import travelContents from 'mixins/travelContents'
import { handleErrors } from 'utils/utils'
import authentication from 'mixins/authentication'
const { toDayOfMonth, toCivilTime, getDateDiff, minutesToHours, getDiff } = date

export default {
  filters: {
    minutesToHours
  },
  components: {
    Timeline,
    DetailsModal,
    MTravelIcon
  },
  mixins: [ travelContents, authentication ],
  data () {
    return {
      showDetails: false,
      loading: false,
      containerHeight: 0
    }
  },
  computed: {
    startTime () {
      switch (this.booking.type) {
      case 'rental':
      case 'ridehailing':
      case 'carclub':
        return this.booking.attributes?.depart_at
      case 'hotel':
        return this.booking.attributes?.check_in
      case 'rail':
      case 'flight':
      case 'ferry':
        return this.booking.attributes?.outbound_legs?.[0]?.depart_at
      case 'bikehire':
      case 'drt':
        return this.booking.attributes.pickup_time
      case 'bus':
        return this.booking.attributes.departing.split(' ')[0] + ' ' + this.booking.attributes.departing.split(' ')[1]
      default:
        return undefined
      }
    },
    endTime () {
      switch (this.booking.type) {
      case 'rental':
      case 'carclub':
        return this.booking.attributes?.arrive_at
      case 'ridehailing':
        return this.booking.attributes?.depart_at
      case 'hotel':
        return this.booking.attributes?.check_out
      case 'rail':
      case 'flight':
      case 'ferry':
        return this.booking.attributes?.outbound_legs?.slice(-1)[0]?.arrive_at
      case 'bus':
        return this.booking.attributes.arriving.split(' ')[0] + ' ' + this.booking.attributes.arriving.split(' ')[1]
      default:
        return undefined
      }
    },
    booking () {
      const booking = Booking.find(this.$route.params.reference)
      return booking
    },
    bookingDetails () {
      const { type, attributes, summary, lookups, driver_details } = this.booking

      switch (type) {
      default:
        return null
      case 'ridehailing':
        return [
          { label: this.$t('taxi.company'), text: summary?.taxi_company },
          { label: this.$t('taxi.driver_details'), text: driver_details || 'No details provided' },
          { label: this.$t('vehicle_type'), text: summary?.vehicle_type }
        ]
      case 'carclub':
        return [
          { label: this.$t('content_type.carclub'), text: this.$t('enterprise_car_club') },
          { label: this.$t('location'), text: attributes.start_location }
        ]
      case 'rental':
        return [
          { label: this.$t('supplier'), text: attributes?.rental_company_name },
          { label: this.$t('pick_up'), text: lookups[attributes?.start_location_branch_code] },
          { label: this.$t('drop_off'), text: lookups[attributes?.end_location_branch_code] }
        ]
      case 'bikehire':
        return [
          { label: this.$t('bikehire.company'), text: 'Brompton Bike Hire' },
          { label: this.$t('location'), text: `${attributes.station_name}, ${attributes.station_postcode} (${attributes.station_id})` },
          { label: this.$t('bikehire.bay_no'), text: attributes.bay_number },
          { label: this.$t('bikehire.unlock_code'), text: attributes.pin_code }
        ]
      }
    },
    bookingReference () {
      switch (this.booking.type) {
      default:
        return null
      case 'rental':
        return this.$t('booking_complete.reference', { partner: this.$tc(`content_type.rental`) }) + ': ' + this.booking.attributes.rental_company_reference
      case 'carclub':
        return this.$t('booking_complete.reference', { partner: this.$tc(`content_type.carclub`) }) + ': ' + this.booking.attributes.supplier_booking_reference
      case 'hotel':
        return this.$t('booking_complete.reference', { partner: this.$tc(`content_type.hotel`) }) + ': ' + (this.booking.attributes.hotel_confirmation_number || 'None provided')
      case 'bikehire':
        return this.$t('booking_complete.reference', { partner: this.$tc(`content_type.bikehire`) }) + ': ' + this.booking.attributes.supplier_reference
      case 'ferry':
        return this.$t('booking.number') + ': ' + this.booking.attributes.booking_number
      }
    },
    footerHeight () {
      /**
       * Extra footer height for train/bus because of "View Ticket" button
       * Which means we need to take extra height away from the container in this case
       */
      return ['rail', 'bus'].includes(this.booking.type) ? '200px' : '165px'
    }
  },
  watch: {
    '$route.params.reference' (val) {
      if (this.$route.name !== 'bookings-show') return
      if (!this.booking) {
        this.getBookingDetails()
      }
    }
  },
  created () {
    if (this.$route.name !== 'bookings-show') return
    this.getBookingDetails()
  },
  updated () {
    // Give height to the .flip element based on its child's height
    const container = document.querySelector('.flip')
    this.containerHeight = container?.firstElementChild?.clientHeight || 0
  },
  methods: {
    toDayOfMonth,
    getDateDiff,
    toDateSpan (start, end) {
      if (!end) return `${date.toNameOfDay(start)} ${toDayOfMonth(start)}`
      const sameDay = toDayOfMonth(start) === toDayOfMonth(end)
      if (sameDay) {
        return `${toDayOfMonth(start)}, ${toCivilTime(start)} - ${toCivilTime(end)}`
      } else {
        return `${toDayOfMonth(start)}, ${toCivilTime(start)} - ${toDayOfMonth(end)}, ${toCivilTime(end)}`
      }
    },
    async getBookingDetails () {
      const partner = this.$store.getters.partner
      this.loading = true
      loading.start({
        message: this.$t('loading.booking_details'),
        partnerSlug: partner.slug,
        spinnerColor: partner.hex
      })

      try {
        await this.$store.dispatch('entities/bookings/loadBooking', this.$route.params.reference)
      } catch (err) {
        handleErrors(err)
      } finally {
        this.loading = false
        loading.stop()
      }
    },
    amend () {
      const alreadyEnded = getDiff(this.endTime, 'minutes').values.minutes
      if (alreadyEnded < 0) {
        return this.$q.notify(this.$t('error.cannot.amend_past'))
      }

      this.$router.push({
        query: {
          type: 'amendment',
          booking: this.booking.reference
        },
        hash: '/concierge'
      })
    },
    cancel () {
      this.$store.dispatch('entities/bookings/cancel', this.booking.reference)
    },
    details () {
      this.showDetails = !this.showDetails
    },
    toggleOutboundReturn () {
      const el = document.querySelector('.flip')
      const oel = document.querySelector('.outbound')
      const rel = document.querySelector('.return')
      if ([...el.classList].includes('preload')) {
        el.classList.remove('flip-it')
        el.classList.remove('preload')
        void el.offsetWidth
        el.classList.add('flip-it')
      } else {
        el.classList.remove('flip-it')
        oel.classList.remove('outbound')
        rel.classList.remove('return')
        void el.offsetWidth
        el.classList.add('flip-it')
        oel.classList.add('return')
        rel.classList.add('outbound')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
// This stops the animation from playing on page load, and is removed/added in script as necessary
.preload
  animation none !important

.booking-container
  padding 0 15px
  color black
  @media (max-height 600px)
    font-size 70% !important

.content-container
  // height calc 100vh - header - sub-header - sub-footer - footer
  height calc(100vh - 64px - 102px - var(--footer-height) - 55px)
  overflow scroll
  perspective 700px

.sub-header, .sub-footer
  background white
  height fit-content
  width 100vw
  margin-left -15px

.sub-header
  position sticky
  top 0
  display grid
  grid-template-columns 50px auto
  grid-template-rows 1fr auto
  margin-bottom 20px
  z-index 10
  box-shadow 0 3px 5px 1px #ccc
  align-items center

.sub-footer
  position sticky
  bottom 0
  display grid
  grid-template-columns repeat(3, 1fr)
  padding 5px 10px 10px
  box-shadow 0 -1px 3px 1px #ccc
  margin-top 15px
  hr
    width 100%
    margin 0
    border 1px solid #ddd
  button
    background transparent
    color var(--q-color-primary)
    border none
    &.done-btn, &.ticket-btn
      margin 5px 0
      padding 5px 0
      border-radius 5px
    &.done-btn
      color white
      background var(--q-color-primary)
      text-transform uppercase
    &.ticket-btn
      border 1px solid var(--q-color-primary)

.span-3
  grid-column span 3

.booking-price, .booking-travel-type
  justify-self flex-end

.booking-travel-type
  font-size 90%

.booking-name
  font-size 120%
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
  padding 15px 5px

.booking-reference
  grid-column span 2
  height fit-content
  background #ddd
  text-align right
  font-size 90%
  padding 5px 10px

.extra-info
  margin 5px 0 15px
  font-size 90%

.booking-details
  font-size 90%
  display grid
  grid-template-columns 1fr 2fr

.view-return-btn
  border none
  width 100%
  background inherit
  color var(--q-color-primary)
  padding 10px
  font-weight 550

@keyframes flip-in {
  0% {
    transform rotateY(0deg)
  }
  100% {
    transform rotateY(180deg)
  }
}

.flip
  position relative
  width 100%
  transform-style preserve-3d
  height var(--child-height)

.flip-it
  animation flip-in 0.4s forwards

.outbound, .return
  position absolute
  width 100%
  background white
  left 0
  -webkit-backface-visibility hidden
  backface-visibility hidden
  transition transform 0.4s
  transform rotateX(0deg) // Fix for Firefox bug with backface-visibility

.return
  transform rotateY(180deg)

.transfer
  position relative
  font-size 110%
  display flex
  align-items center
  justify-content space-between
  width 70%
  margin 5px 0

.outbound, .return
  .q-icon
    transform rotateY(180deg) !important

</style>
