<template>
  <GmapMap
    ref="map"
    class="route-map"
    aria-label="Map"
    :options="mapConfig"
    :center="startLocation"
    :zoom="15"
  >
    <GmapMarker
      v-if="startLocation"
      ref="current-location"
      :position="startLocation"
      aria-label="Requester Location"
      class="marker"
      :icon="{
        url: require(`assets/markers/deviceLocation.png`)
      }"
    />
    <GmapMarker
      v-if="taxiLocation"
      ref="taxi-location"
      :position="taxiLocation"
      aria-label="Taxi Location"
      class="marker"
      :icon="{
        url: require(`assets/markers/taxi-solid.svg`)
      }"
    />
  </GmapMap>
</template>

<script>
import mapConfig from 'pages/ondemand/ride-hailing/map-config'
import { getGoogleMapsAPI } from 'gmap-vue'

export default {
  props: ['points', 'startLocation'],
  data () {
    return {
      mapConfig
    }
  },
  computed: {
    google: getGoogleMapsAPI,
    taxiLocation () {
      if (!this.points.length) return null
      const latestPoint = this.points[this.points.length - 1]
      return {
        lat: latestPoint?.latitude,
        lng: latestPoint?.longitude
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.route-map
  height: calc(100vh - 64px);
  width: 100%;
  transition: height 0.3s;
  @media (max-width 768px)
    height 60vh
</style>
