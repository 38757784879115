<template>
  <m-modal
    :show="show"
    :max-height="maxHeight"
    @close-modal="$emit('close-modal')"
  >
    <h6 @click="() => showDetails = !showDetails">
      {{ $t('booking.details') }}
    </h6>
    <m-dropdown-transition
      :collapsed="!showDetails"
      :max-height="maxHeight"
    >
      <p v-for="detail in details" :key="detail.label">
        <strong>{{ detail.label }}: </strong><br>
        {{ detail.text }}
      </p>
    </m-dropdown-transition>
    <h6 @click="() => showNotes = !showNotes">
      {{ $t('additional_information') }}
    </h6>
    <m-dropdown-transition
      :collapsed="!showNotes"
      :max-height="maxHeight"
    >
      <p v-for="(note, i) in notes" :key="`note-${i}`">
        {{ note.body }}
      </p>
    </m-dropdown-transition>
  </m-modal>
</template>

<script>
import { MDropdownTransition, MModal } from 'components/'

export default {
  components: { MDropdownTransition, MModal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    details: Array,
    notes: Array
  },
  data () {
    return {
      showDetails: false,
      showNotes: false,
      maxHeight: '70vh'
    }
  }
}
</script>
