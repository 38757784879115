<template>
  <div class="status-box">
    <h6>{{ $t('status') }}</h6>
    <div v-for="note in notes" :key="note.id" class="status-text">
      {{ note.body }}
      <q-separator />
    </div>
  </div>
</template>

<script>
export default {
  props: ['notes']
}
</script>

<style lang="stylus" scoped>
.status-box
  background white
  height 350px
  width 250px
  position absolute
  top 0
  right 0
  margin 20px
  padding 5px 10px
  overflow-y auto
  border-radius 25px
  border 2px solid var(--q-color-primary)

.status-text
  padding-top 5px

.q-separator
  margin-top 5px
</style>
